<template>
  <div class="p-9 py-3 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
    <h5 class="text-dark mb-0 fw-semibold">Profile</h5>
  </div>
  <div v-if="client" class="position-relative">
    <div class="chat-box p-9" data-simplebar="init">
      <div class="simplebar-wrapper" style="margin: -20px;">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
            <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
              <div class="simplebar-content" style="padding: 20px;">
                <div class="chat-list chat active-chat" data-user-id="1">
                  <div class="hstack align-items-start mb-7 pb-1 align-items-center justify-content-between">
                    <div class="d-flex align-items-center gap-3">
                      <div class="d-flex align-items-center">
                        <div class="user-profile-img me-3"><div class="user-avatar"><i class="fa fa-building fw-light"></i></div></div>
                        <div :class="[{ 'has-danger': !isFieldValid('name') }]">
                          <h6 v-if="!editing" class="fw-semibold fs-4 mb-0">{{ client.name }}</h6>
                          <input v-if="editing" v-model="fields.name.value" type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 mb-7">
                      <p class="mb-1 fs-2">Tags</p>
                      <template v-if="!editing">
                        <TagComponent v-for="tag in client.tags" :key="tag.id" :tag="tag" />
                      </template>
                      <Multiselect
                        v-if="editing"
                        v-model="clientTags"
                        :options="tags"
                        value-prop="id"
                        mode="tags"
                        :create-option="false"
                        label="name"
                      />
                    </div>
                    <div class="col-lg-4 col-md-6 mb-7">
                      <p class="mb-1 fs-2">Status</p>
                      <span v-if="!editing" :class="`badge status-${client.status}`">{{ capitalizeFirstLetter(client.status) }}</span>
                      <select v-if="editing" class="form-select" v-model="fields.status.value" required>
                        <option value=""></option>
                        <option v-for="status in CLIENT_STATUSES" :key="status" :value="status">{{ capitalizeFirstLetter(status) }}</option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-9"
                         :class="[{ 'has-danger': !isFieldValid('sfdc_account_id') }]">
                      <p class="mb-1 fs-2">Salesforce ID</p>
                      <h6 v-if="!editing" class="fw-semibold mb-0">{{ client.sfdc_account_id}} </h6>
                      <input v-if="editing" v-model="fields.sfdc_account_id.value" type="text" class="form-control" />
                      <small class="form-control-feedback" v-if="!isFieldValid('sfdc_account_id')">
                        Salesforce ID must be 18 characters
                      </small>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-7">
                      <p class="mb-1 fs-2">Industry</p>
                      <h6 v-if="!editing" class="fw-semibold mb-0">{{ client.industry || '-' }}</h6>
                      <select v-if="editing" class="form-select" v-model="fields.industry.value" required>
                        <option value=""></option>
                        <option v-for="industry in CLIENT_INDUSTRIES" :key="industry" :value="industry">{{ industry }}</option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <p class="mb-2 fs-2">Notes</p>
                      <h6 v-if="!editing" class="fw-semibold mb-0">{{client.notes || '-'}}</h6>
                      <textarea v-if="editing" cols="30" rows="5" class="form-control" v-model="fields.notes.value"></textarea>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-7">
                      <p class="mb-1 fs-2">Appointment Exclusivity</p>
                      <h6 v-if="!editing" class="fw-semibold mb-0">
                        <template v-if="client.appointment_exclusivity">
                          <i class="fa fa-check me-1"></i> Enabled
                        </template>
                        <template v-else>
                          <i class="fa fa-times me-1"></i> Disabled
                        </template>
                      </h6>
                      <div v-if="editing" class="form-check form-switch mt-2">
                        <input class="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckDefault"
                               v-model="fields.appointment_exclusivity.value"
                               :checked="client.appointment_exclusivity"
                        >
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </div>
                  </div>
                  <div class="border-bottom p-0 pb-lg-7 mb-4"></div>
                  <div class="d-flex align-items-center gap-2">
                    <button v-if="!editing" class="btn btn-primary fs-2" @click="editing = true">Edit</button>
                    <button v-if="editing" class="btn btn-primary fs-2" @click="save">Save</button>
                    <button v-if="editing" class="btn btn-outline-primary fs-2" @click="editing = false">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="simplebar-placeholder" style="width: auto; height: 578px;"></div>
      </div>
      <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
        <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
      </div>
      <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
        <div class="simplebar-scrollbar" style="height: 128px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, watch, onMounted, defineEmits } from 'vue';
import { capitalizeFirstLetter } from '@/helpers/util';
import { CLIENT_INDUSTRIES, CLIENT_STATUSES } from '@/helpers/constants';
import { useFormValidation } from '@/helpers/form-validation';
import TagComponent from '@/components/TagComponent.vue';
import Multiselect from '@vueform/multiselect';
import { useApi } from '@/helpers/udab-api';
import { useToast } from 'vue-toastification';

const toast = useToast();
const editing = ref(false);
const tags = ref([]);
const clientTags = ref([]);
const emit = defineEmits(['update:client']);
const api = useApi();

const props = defineProps({
  client: Object
});

const { fields, validate, isFieldValid, fieldValues } = useFormValidation({});

const save = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.patch(`/clients/${props.client.id}`, data);

    if (response) {
      emit('update:client', response);
      editing.value = false;
      toast.success('Client successfully created');
    }
  }
};

const init = async () => {
  if (props.client) {
    tags.value = await api.get('/tags', { object: 'client' });

    fields.value = {
      name: { value: props.client.name, validate: 'not-empty' },
      tags: { value: props.client.tags },
      status: { value: props.client.status, validate: 'not-empty' },
      sfdc_account_id: { value: props.client.sfdc_account_id, validate: 'exact-length', options: { length: 18 } },
      industry: { value: props.client.industry, validate: 'not-empty' },
      appointment_exclusivity: { value: props.client.appointment_exclusivity },
      notes: { value: props.client.notes }
    };

    clientTags.value = props.client.tags.map(tag => tag.id);
  }
};

onMounted(init);
watch(() => props.client, init);
watch(() => clientTags.value, () => {
  fields.value.tags.value = clientTags.value;
});
</script>
<style scoped>
.form-check-input {
  width: 3.1em;
  height: 1.75em;
}
</style>
