<template>
  <div class="p-9 py-3 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
    <h5 class="text-dark mb-0 fw-semibold">Landing Pages</h5>
  </div>
  <div v-if="landingPages && !landingPages.length" class="p-9 text-center">
    <h6 class="my-5">No landing pages added yet.</h6>
    <button class="btn btn-primary" @click="triggerModal">Add Landing Page</button>
  </div>
  <div v-if="landingPages && landingPages.length" class="p-9">
    <div class="d-flex justify-content-end mb-3">
      <button class="btn btn-primary" @click="triggerModal">
        <i class="fa fa-plus-circle"></i>
        <span class="ms-2">Add</span>
      </button>
    </div>
    <table id="landing-pages" class="table table-hover">
      <thead>
        <tr>
          <th>Landing Page URL</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="landingPage in landingPages" :key="landingPage.id">
          <td>
            {{ landingPage.url }}
            <a href="{{ landingPage.url }}">
              <i class="ms-3 fa fa-external-link-alt text-info"></i>
            </a>
          </td>
          <td>{{ landingPage.type_label }}</td>
          <td>
            <button class="btn btn-primary me-2" @click="deleteLandingPage(landingPage.id)"><i class="fa fa-times"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Teleport to="body" v-if="showAddModal">
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="vertical-center-modal" aria-modal="true"
         role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myLargeModalLabel">
              Add Landing Page
            </h4>
            <button type="button" class="btn-close" @click="closeAddModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <Multiselect
              v-model="selectedPage"
              :close-on-select="true"
              :filter-results="false"
              :min-chars="2"
              :resolve-on-load="false"
              :infinite="true"
              :limit="10"
              :clear-on-search="true"
              :delay="0"
              :searchable="true"
              :disabled="resyncing"
              noOptionsText="Search by URL..."
              :options="async (query) => {
                return await loadOptions(query)
              }"
              @open="(select$) => {
                if (select$.noOptions) {
                  select$.resolveOptions()
                }
              }"
            />
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <strong>Can't find a landing page?</strong><br>
              <span><a href="javascript:void(0);" @click="resync" class="text-decoration-underline">Re-sync with Unbounce</a> <i :class="['fa ms-3 fa-spin fa-spinner', {'d-none': !resyncing}]"></i></span>
            </div>
            <button type="button"
                    class="btn btn-primary font-medium waves-effect text-start"
                    :disabled="!selectedPage"
                    @click="addLandingPage"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </Teleport>
</template>
<script setup>
import { defineProps, onMounted, ref } from 'vue';
import { useApi } from '@/helpers/udab-api';
import { useToast } from 'vue-toastification';
import swal from 'sweetalert';
import Multiselect from '@vueform/multiselect';
import { debounce } from 'lodash';

const props = defineProps({
  client: Object
});

const toast = useToast();
const api = useApi();

const showAddModal = ref(false);
const landingPages = ref([]);
const resyncing = ref(false);

const resync = async () => {
  resyncing.value = true;

  await fetch(window.$udabApiUrl + '/landing-pages/sync');

  resyncing.value = false;
};

const triggerModal = () => {
  showAddModal.value = true;
};

const selectedPage = ref(null);

const loadOptions = async (query) => {
  if (!query || query.length < 2) return [];
  try {
    const data = await fetch(window.$udabApiUrl + `/landing-pages/unassigned?query=${encodeURIComponent(query)}`);
    const landingPages = await data.json();

    return landingPages.map(landingPage => ({
      value: landingPage.id,
      label: landingPage.url
    }));
  } catch (error) {
    console.error('Error fetching options:', error);
    return [];
  }
};

const closeAddModal = () => {
  showAddModal.value = false;
};

const getLandingPages = async () => {
  landingPages.value = await api.get('/landing-pages', { client: props.client.id });
};

onMounted(() => {
  getLandingPages();
});

const addLandingPage = async () => {
  const response = await api.post('/landing-pages', { client: props.client.id, page: selectedPage.value });

  if (response) {
    getLandingPages();
    closeAddModal();

    toast.success('Landing Page successfully added');
  }
};

const deleteLandingPage = (page_id) => {
  swal({
    title: 'Are you sure?',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })
    .then(async (confirmed) => {
      if (confirmed) {
        await api.deleteRequest(`/landing-pages/${props.client.id}/${page_id}`);
        getLandingPages();
      }
    });
};
</script>

<style scoped>
table#mailboxes td {
  vertical-align: middle;
}
</style>

<style scoped>
#landing-pages th:nth-child(1),
#landing-pages td:nth-child(1)
{
  width: auto;
}
#landing-pages th:nth-child(2),
#landing-pages td:nth-child(2),
#landing-pages th:nth-child(3),
#landing-pages td:nth-child(3) {
  width: 10%;
  white-space: nowrap;
}
.modal button:disabled {
  background: #ef3f2a;
  border: none;
}
td {
  vertical-align: middle;
}
</style>
